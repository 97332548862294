import { Suspense, lazy, useEffect } from "react";

import { Box } from "@mui/material";

import Metadate from "../components/common/metadate/metadate";
import { ScrollToTop } from "../components/special";
import AnimatedOutlet from "../components/special/animated-outlet";
import { useLang } from "../hooks";
import { loadZohoChat } from "../utils/helpers/zohoChat";

const Header = lazy(() => import("../components/common/header/header"));
const MobileMenu = lazy(() => import("../components/common/mobile-menu/mobile-menu"));

const IndexLayout = () => {
  const lang = useLang();

  useEffect(() => {
    const timer = setTimeout(() => loadZohoChat(lang), 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ScrollToTop />
      {/*Moved PaymentSystemsLoader to header -> logo*/}
      <Metadate />
      <Box className="wrapper">
        <Suspense fallback={<></>}>
          <Header />
        </Suspense>
        <AnimatedOutlet />
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up("bp1200")]: {
              display: "none"
            }
          })}
        >
          <Suspense fallback={<></>}>
            <MobileMenu />
          </Suspense>
        </Box>
      </Box>
    </>
  );
};

export default IndexLayout;
