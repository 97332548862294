// eslint-disable-next-line import/namespace
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { useLang } from "../../../hooks";
import useCurrentSeo from "../../../hooks/use-current-seo";
import { generateMetaTagsAndLinks } from "../../../utils/helpers";

const Metadate = () => {
  const seo = useCurrentSeo();
  const lang = useLang();
  const title = seo?.title;
  const description = seo?.description;
  const language = seo?.language || lang;
  const homeURL = process.env.REACT_APP_HOME_URL ?? "https://youproxy.io";

  const { pathname } = useLocation();

  const ensureLangPrefix = (path, lang) => {
    if (path === "/") return "/";
    if (!path.startsWith(`/${lang}/`)) {
      return `/${lang}${path.startsWith("/") ? "" : "/"}${path}`;
    }
    return path;
  };

  const getCanonicalLink = () => {
    const canonicalPath = ensureLangPrefix(pathname, language);
    return <link rel="canonical" href={`${homeURL}${canonicalPath}`.replace(/\/+$/, "/")} />;
  };

  return (
    <Helmet>
      {generateMetaTagsAndLinks(title, description, language)}
      {getCanonicalLink()}
    </Helmet>
  );
};

export default Metadate;
