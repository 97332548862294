export const getRobotContent = () => {
  // eslint-disable-next-line no-console
  console.log("REACT_APP_MODE: ", process.env.REACT_APP_MODE);
  const mode = process.env.REACT_APP_MODE ?? "prod";
  const ROBOTS = {
    ALL: "all",
    NONE: "none"
  };

  try {
    return mode === "prod" ? ROBOTS.ALL : ROBOTS.NONE;
  } catch (ignored) {
    // eslint-disable-next-line no-console
    console.log("Error in getRobotContent:", ignored);
    return ROBOTS.NONE;
  }
};
